
import { v4 as uuid } from 'uuid'
import {
  computed,
  defineComponent,
  nextTick,
  onUpdated,
  ref,
  onMounted,
} from 'vue'
import { VNodeArrayChildren } from '@vue/runtime-core'
import { toggleIcon } from '@/components/vue-collapsible-panel.constant'
import { useCollapsiblePanelStore } from '@/components/composables/vue-collapsible-panel.store'

export default defineComponent({
  name: 'VueCollapsiblePanel',
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const idPanel = `panel-${uuid()}`
    const panelRef = ref<HTMLElement>()
    const bodyRef = ref<HTMLElement>()
    const bodyContentRef = ref<HTMLElement>()
    const {
      panelExpanded,
      togglePanelExpandedStatus,
      setPanelExpandedStatus,
    } = useCollapsiblePanelStore()

    const body = computed(
      () => ({
        hasContent: context.slots.content && (context.slots.content()[0].children as VNodeArrayChildren).length > 0,
        dataKey: uuid(),
      }),
    )

    const idGroup = computed(() => {
      return panelRef.value?.parentElement?.getAttribute('data-id-group') || ''
    })

    const isExpanded = computed(
      () => panelExpanded(idGroup.value, idPanel).value && body.value.hasContent,
    )

    const toggle = (): void => {
      if (!body.value.hasContent) return

      togglePanelExpandedStatus(idGroup.value, idPanel)
    }

    const collapse = (element: HTMLElement): void => {
      element.style.height = '0'
    }

    const expand = (element: HTMLElement): void => {
      element.style.height = `${element.scrollHeight}px`
    }

    const updateBodyHeight = async(): Promise<void> => {
      await nextTick()

      if (!bodyRef.value || !bodyContentRef.value) return

      bodyRef.value.style.height = `${Math.min(bodyContentRef.value.scrollHeight, bodyRef.value.scrollHeight)}px`
    }

    onMounted(() => {
      setPanelExpandedStatus(idGroup.value, idPanel, props.expanded)
    })

    onUpdated(() => {
      updateBodyHeight()
    })

    return {
      body,
      panelRef,
      bodyRef,
      bodyContentRef,
      isExpanded,
      collapse,
      expand,
      toggle,
      toggleIcon,
    }
  },
})
